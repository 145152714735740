import axios from 'axios';

const API = 'promociones';

const ENDPOINTS = {
    getListarBannersPromociones(){
        return axios.get(`${API}`)
    },
    getPromociones(id){
        return axios.get(`${API}/${id}`)
    },
    getProductosPromociones(id, params){
        return axios.get(`${API}/${id}/productos`,{params})
    },
};

export default ENDPOINTS;